/* scrollbar */
@import "simplebar-react/dist/simplebar.min.css";

/* carousel */
@import "./sections/trending/view/card.css";

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
