.embla {
  max-width: 60rem;
  margin: auto;
  --slide-spacing: 0.1rem;
  --slide-size: 60%;
  --slide-height: calc(var(--slide-size) * 0.8);
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  width: 100%;
  /* border: 2px solid white; */
}

.embla__slide {
  transition: opacity 0.5s ease-in-out;
  transition-property: opacity, transform;
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;

  /* padding-left: var(--slide-spacing); */
}

.embla__slide:not(.is-snapped) {
  /* opacity: 0.16; */
  transform: scale(0.7);
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 0.2rem;
  /* border:2px solid white; */
}

.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 0.6rem; */
  /* border: 2px solid white; */
  align-items: center;
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
  /* border:0.5px solid white/10; */
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__play {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  /* padding: 0; */
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  color: var(--text-body);
  font-weight: 700;
  font-size: 1.4rem;
  padding: 0 2.4rem;
  min-width: 8.4rem;
}

.embla__progress {
  border-radius: 1.8rem;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  background-color: #212121;
  position: relative;
  height: 0.4rem;
  justify-self: flex-end;
  align-self: center;
  width: 13rem;
  max-width: 90%;
  overflow: hidden;
}

.embla__progress__bar {
  /* background-color: var(--text-body); */
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}

.embla__controls {
  grid-template-columns: auto 1fr auto;
}

.embla__progress {
  justify-self: center;
  transition: opacity 0.3s ease-in-out;
  width: 8rem;
}

.embla__progress--hidden {
  opacity: 0;
}

.embla__progress__bar {
  animation-name: autoplay-progress;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.embla__progress--hidden .embla__progress__bar {
  animation-play-state: paused;
}

@keyframes autoplay-progress {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@media (max-width: 750px) {
  .embla {
    --slide-size: 100%;
    --slide-height: 81dvh;
  }

  .embla__container {
    touch-action: pan-x pinch-zoom;
    margin-top: calc(var(--slide-spacing) * -1);
    height: calc(var(--slide-spacing) + var(--slide-height));
    flex-direction: column;
    width: auto;
  }

  .embla__slide {
    min-height: 0;
    padding-top: var(--slide-spacing);
    min-width: initial;
  }
}
